export const capitalize = str =>
  str && str.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())

export const colors = {
  normal: "#B7B7AA",
  fire: "#FF4747",
  water: "#00B3FA",
  electric: "#FFE25A",
  grass: "#73D963",
  ice: "#6FF3FE",
  fighting: "#B55245",
  poison: "#B55A9C",
  ground: "#E6C368",
  flying: "#65A6FA",
  psychic: "#FF5BAF",
  bug: "#C0D146",
  rock: "#D2BB7A",
  ghost: "#7374CC",
  dragon: "#8877F8",
  dark: "#976858",
  steel: "#C4C2D6",
  fairy: "#FFACFA",
}

const SUNNY = "☀️"
const RAINY = "🌧️"
const PARTLY = "🌦️"
const CLOUDY = "☁️"
const WINDY = "🌬️"
const SNOW = "❄️"
const FOG = "🌫️"

export const getWeather = (type1, type2) => {
  const weathers = []
  if (["grass", "fire", "ground"].indexOf(type1) !== -1) weathers.push(SUNNY)
  if (["water", "electric", "bug"].indexOf(type1) !== -1) weathers.push(RAINY)
  if (["normal", "rock"].indexOf(type1) !== -1) weathers.push(PARTLY)
  if (["fairy", "fighting", "poison"].indexOf(type1) !== -1)
    weathers.push(CLOUDY)
  if (["flying", "dragon", "psychic"].indexOf(type1) !== -1)
    weathers.push(WINDY)
  if (["ice", "steel"].indexOf(type1) !== -1) weathers.push(SNOW)
  if (["dark", "ghost"].indexOf(type1) !== -1) weathers.push(FOG)

  // In order to have type's weaher before type2's
  if (
    ["grass", "fire", "ground"].indexOf(type1) === -1 &&
    ["grass", "fire", "ground"].indexOf(type2) !== -1
  )
    weathers.push(SUNNY)
  if (
    ["water", "electric", "bug"].indexOf(type1) === -1 &&
    ["water", "electric", "bug"].indexOf(type2) !== -1
  )
    weathers.push(RAINY)
  if (
    ["normal", "rock"].indexOf(type1) === -1 &&
    ["normal", "rock"].indexOf(type2) !== -1
  )
    weathers.push(PARTLY)
  if (
    ["fairy", "fighting", "poison"].indexOf(type1) === -1 &&
    ["fairy", "fighting", "poison"].indexOf(type2) !== -1
  )
    weathers.push(CLOUDY)
  if (
    ["flying", "dragon", "psychic"].indexOf(type1) === -1 &&
    ["flying", "dragon", "psychic"].indexOf(type2) !== -1
  )
    weathers.push(WINDY)
  if (
    ["ice", "steel"].indexOf(type1) === -1 &&
    ["ice", "steel"].indexOf(type2) !== -1
  )
    weathers.push(SNOW)
  if (
    ["dark", "ghost"].indexOf(type1) === -1 &&
    ["dark", "ghost"].indexOf(type2) !== -1
  )
    weathers.push(FOG)
  return weathers
}

export const cpmultiplier = [{ level: 20, multiplier: 0.59740001 }]

export const getCP = (pokemon, atk, def, sta, level = 20) => {
  const cpm =
    level === 20
      ? 0.59740001
      : cpmultiplier.find(o => o.level === level).multiplier
  return Math.floor(
    (cpm *
      cpm *
      (pokemon.stats.baseAttack + atk) *
      Math.sqrt(pokemon.stats.baseDefense + def) *
      Math.sqrt(pokemon.stats.baseStamina + sta)) /
      10
  )
}

export const sections = {
  0: "",
  1: "★ Tier 1 raid bosses",
  2: "★★ Tier 2 raid bosses",
  3: "★★★ Tier 3 raid bosses",
  4: "★★★★ Tier 4 raid bosses",
  5: "★★★★★ Tier 5 raid bosses",
  6: "EX legendary raid bosses",
  mega: "Mega raid bosses",
}

export const getAttributes = pokemonTypes => {
  let attributes = {}
  for (const pokemonType of pokemonTypes) {
    if (!pokemonType) break
    for (const less_from of types[pokemonType].less_from) {
      if (isNaN(attributes[less_from])) {
        attributes[less_from] = 1
      } else {
        attributes[less_from] += 1
      }
    }
    for (const more_from of types[pokemonType].more_from) {
      if (isNaN(attributes[more_from])) {
        attributes[more_from] = -1
      } else {
        attributes[more_from] -= 1
      }
    }
  }

  let resistances = {}
  for (const attribute of Object.keys(attributes)) {
    if (attributes[attribute] === 2)
      resistances[attribute] = attributes[attribute]
  }
  for (const attribute of Object.keys(attributes)) {
    if (attributes[attribute] === 1)
      resistances[attribute] = attributes[attribute]
  }

  let weaknesses = {}
  for (const attribute of Object.keys(attributes)) {
    if (attributes[attribute] === -2)
      weaknesses[attribute] = attributes[attribute]
  }
  for (const attribute of Object.keys(attributes)) {
    if (attributes[attribute] === -1)
      weaknesses[attribute] = attributes[attribute]
  }

  return { weaknesses, resistances }
}

export const types = {
  "-": { less_to: [], more_to: [], less_from: [], more_from: [] },
  normal: {
    less_to: ["rock", "steel", "ghost"],
    more_to: [],
    less_from: ["ghost"],
    more_from: ["fighting"],
  },
  fire: {
    less_to: ["fire", "water", "rock", "dragon"],
    more_to: ["grass", "ice", "bug", "steel"],
    less_from: ["fairy", "ice", "grass", "fire", "steel", "bug"],
    more_from: ["ground", "rock", "water"],
  },
  water: {
    less_to: ["water", "grass", "dragon"],
    more_to: ["fire", "ground", "rock"],
    less_from: ["steel", "fire", "water", "ice"],
    more_from: ["grass", "electric"],
  },
  electric: {
    less_to: ["ground", "electric", "grass", "dragon"],
    more_to: ["water", "flying"],
    less_from: ["flying", "steel", "electric"],
    more_from: ["ground"],
  },
  grass: {
    less_to: ["fire", "grass", "poison", "flying", "bug", "dragon", "steel"],
    more_to: ["water", "ground", "rock"],
    less_from: ["ground", "water", "grass", "electric"],
    more_from: ["flying", "poison", "bug", "fire", "ice"],
  },
  ice: {
    less_to: ["fire", "water", "ice", "steel"],
    more_to: ["grass", "ground", "flying", "dragon"],
    less_from: ["ice"],
    more_from: ["fighting", "rock", "steel", "fire"],
  },
  fighting: {
    less_to: ["ghost", "poison", "flying", "psychic", "bug", "fairy"],
    more_to: ["normal", "ice", "rock", "dark", "steel"],
    less_from: ["rock", "bug", "dark"],
    more_from: ["flying", "psychic", "fairy"],
  },
  poison: {
    less_to: ["steel", "poison", "ground", "rock", "ghost"],
    more_to: ["grass", "fairy"],
    less_from: ["fighting", "poison", "bug", "grass", "fairy"],
    more_from: ["ground", "psychic"],
  },
  ground: {
    less_to: ["flying", "grass", "bug"],
    more_to: ["fire", "electric", "poison", "rock", "steel"],
    less_from: ["poison", "rock", "electric"],
    more_from: ["water", "grass", "ice"],
  },
  flying: {
    less_to: ["electric", "rock", "steel"],
    more_to: ["grass", "fighting", "bug"],
    less_from: ["fighting", "ground", "bug", "grass"],
    more_from: ["rock", "electric", "ice"],
  },
  psychic: {
    less_to: ["dark", "psychic", "steel"],
    more_to: ["fighting", "poison"],
    less_from: ["fighting", "psychic"],
    more_from: ["bug", "ghost", "dark"],
  },
  bug: {
    less_to: [
      "fire",
      "fighting",
      "poison",
      "flying",
      "ghost",
      "steel",
      "fairy",
    ],
    more_to: ["grass", "psychic", "dark"],
    less_from: ["fighting", "ground", "grass"],
    more_from: ["flying", "rock", "fire"],
  },
  rock: {
    less_to: ["fighting", "ground", "steel"],
    more_to: ["fire", "ice", "flying", "bug"],
    less_from: ["normal", "flying", "poison", "fire"],
    more_from: ["fighting", "ground", "steel", "water", "grass"],
  },
  ghost: {
    less_to: ["normal", "dark"],
    more_to: ["psychic", "ghost"],
    less_from: ["normal", "fighting", "poison", "bug"],
    more_from: ["ghost", "dark"],
  },
  dragon: {
    less_to: ["fairy", "steel"],
    more_to: ["dragon"],
    less_from: ["fire", "water", "grass", "electric"],
    more_from: ["ice", "dragon", "fairy"],
  },
  dark: {
    less_to: ["fighting", "dark", "fairy"],
    more_to: ["psychic", "ghost"],
    less_from: ["ghost", "psychic", "dark"],
    more_from: ["fighting", "bug", "fairy"],
  },
  steel: {
    less_to: ["fire", "water", "electric", "steel"],
    more_to: ["ice", "rock", "fairy"],
    less_from: [
      "normal",
      "flying",
      "poison",
      "rock",
      "bug",
      "steel",
      "grass",
      "psychic",
      "ice",
      "dragon",
      "fairy",
    ],
    more_from: ["fighting", "ground", "fire"],
  },
  fairy: {
    less_to: ["fire", "poison", "steel"],
    more_to: ["fighting", "dragon", "dark"],
    less_from: ["fighting", "bug", "dragon", "dark"],
    more_from: ["poison", "steel"],
  },
}
