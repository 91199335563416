import React from "react"

import { capitalize, colors, getAttributes } from "../utils/stats"

export default ({ pokemon, image = false }) => {
  const { weaknesses, resistances } = getAttributes([
    pokemon.type1,
    pokemon.type2,
  ])

  return (
    <div
      style={{
        minHeight: 80,
        border: "1px solid #e2e2e2",
        display: "flex",
      }}
    >
      <img
        src={pokemon.image.publicURL}
        alt={pokemon.name}
        style={{
          width: 64,
          height: 64,
          alignSelf: "center",
          margin: "0 20px",
        }}
      />
      <div style={{ padding: "8px 0", flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 4,
          }}
        >
          <div style={{ fontSize: ".9rem", fontWeight: "bold" }}>
            {capitalize(pokemon.name)}{" "}
            {pokemon.form !== "Normal" && capitalize(pokemon.form)} #
            {pokemon.id}
          </div>
        </div>

        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ fontSize: ".8rem" }}>Max CP {pokemon.maxCp}</div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                justifyContent: "center",
                fontSize: ".6rem",
                fontWeight: "bold",
                color: "white",
                backgroundColor: colors[pokemon.type1],
                padding: "2px 4px",
                marginRight: 4,
                borderRadius: 4,
              }}
            >
              {pokemon.type1.toUpperCase()}
            </div>
            {pokemon.type2 && (
              <div
                style={{
                  fontSize: ".6rem",
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: colors[pokemon.type2],
                  padding: "2px 4px",
                  marginRight: 4,
                  borderRadius: 4,
                }}
              >
                {pokemon.type2.toUpperCase()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
